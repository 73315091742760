import { Backgrounds, NavigateBar, PopupPicture } from "../../components";
import { Layout } from "../../utils/Layout";
import Background from "../../assets/background/white-background.png";
import Download from "../../assets/icons/download.png";
import { useState } from "react";
import { Books } from "./ui/Books";

export const PandingMemory = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [getPhoto, setGetPhoto] = useState({});
  
  return (
    <Layout>
        <NavigateBar />
        <Backgrounds background={Background} logo={Download}>
          <Books setOpenPopup={setOpenPopup} setGetPhoto={setGetPhoto} />
          {openPopup && <PopupPicture getPhoto={getPhoto} setOpenPopup={setOpenPopup} />}
        </Backgrounds>
    </Layout>
  )
};
