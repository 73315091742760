import axios from "axios";
import { getAuthToken } from "../../hooks/localstorage";

const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8080'
export const axiosConfig = axios.create({
  baseURL: `${baseUrl}/api/`,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosConfig.interceptors.request.use((req) => {
  const token = getAuthToken();
  if (token) {
    req.headers = {
      ...req.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return req;
});
