import { Select } from "antd";
import { Option } from "antd/lib/mentions";
import { useState } from "react";

interface Props {
  options: any[];
  value?: string;
  placeholder: string;
  onChange: (value: string) => void;
  cv?: boolean;
}

export const SelectInput: React.FC<Props> = ({
  options,
  value,
  placeholder,
  onChange,
  cv,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    value
  );

  const handleChange = (value: string) => {
    setSelectedOption(value);
    onChange(value);
  };

  return (
    <Select
      style={{
        width: "300px",
      }}
      showSearch
      value={selectedOption}
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={handleChange}
      filterOption={(input, option) =>
        (option?.children as unknown as string)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
    >
      {options?.map((option: any) =>
        cv ? (
          <Option key={option} value={option}>
            {option}
          </Option>
        ) : (
          <Option key={option.email} value={option.name}>
            {option.name}
          </Option>
        )
      )}
    </Select>
  );
};
