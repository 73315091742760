import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import styles from "./Books.module.scss";
import { fetchGetMemories } from "../../../../store/api/fetchGetMemories";
import {
  getNameToken,
  getTimelineIdToken,
  setMemoryIdToken,
} from "../../../../hooks/localstorage";
import { TailSpin } from "react-loader-spinner";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow from "../../../../assets/icons/arrow-down.svg";
import Moment from "react-moment";
import Masonry from "react-masonry-css";
import Share from "../../../../assets/icons/share-icon.png";
import Edit from "../../../../assets/icons/edit-icon.png";
import { Link } from "react-router-dom";
import Plyr from "plyr";
import "plyr/dist/plyr.css";
import { RootState } from "../../../../store";

interface IBooks {
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setGetPhoto: (value: {}) => void;
  setOpenShare: Dispatch<SetStateAction<boolean>>;
  setGetMemoryID: (value: {}) => void;
}

export const Books: FC<IBooks> = ({
  setOpenPopup,
  setGetPhoto,
  setOpenShare,
  setGetMemoryID,
}) => {
  const dispatch = useAppDispatch();
  const id = getTimelineIdToken();
  const [selectedButton, setSelectedButton] = useState("");

  const [isImagesLoading, setIsImagesLoading] = useState(false);
  const { data, isLoading } = useAppSelector(
    (state) => state.getMemoriesReducer
  );
  const { dataSingle } = useAppSelector(
    (state: RootState) => state.singleTimelineReducer
  );

  const [memories, setMemories] = useState<any>([]);
  useEffect(() => {
    setMemories(data);
  }, [data]);

  const { categoryData, participantData, locationData, dateData } =
    useAppSelector((state) => state.filterHubReducer);

  useEffect(() => {
    let filteredData = data;

    if (categoryData) {
      filteredData = filteredData.filter((el) => el.cv === categoryData);
    }

    if (participantData && participantData.length > 0) {
      filteredData = filteredData.filter((el) =>
        participantData.some(participant => el.participants.includes(participant))
      );
    }

    if (locationData) {
      filteredData = filteredData.filter((el) => el.location === locationData);
    }

    if (dateData) {
      const [day, month, year] = dateData.split("-");
      const searchDate = new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day)
      );
      filteredData = filteredData.filter(
        (el) =>
          new Date(el.date).toLocaleDateString() ===
          searchDate.toLocaleDateString()
      );
    }

    setMemories(filteredData);
    
  }, [categoryData, data, dateData, locationData, participantData]);

  useEffect(() => {
    dispatch(fetchGetMemories(id));
  }, [dispatch, id]);

  const openPhoto = (id: any,memoryId:string) => {
    setOpenPopup(true);
    setGetPhoto(id);
    setMemoryId(memoryId)
  };

  const [allPages, setAllPages] = useState<Record<string, any[]>>({});
  const [pages, setPages] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [imagesToShow, setImagesToShow] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentYear, setCurrentYear] = useState<string>("");

  useEffect(() => {
    if (pages.length > 0) {
      setIsImagesLoading(true);
      setImagesToShow([
        ...pages[currentPage]?.bulkPageFiles,
        ...pages[currentPage]?.memoryFiles,
      ]);
    }
  }, [currentPage, pages]);

  useEffect(()=>{
    if(imagesToShow && imagesToShow.length){
      const promises = imagesToShow.map((image) => {
        return new Promise((resolve) => {
          if (image.path.includes("mp4") || image.path.includes("MP4") || 
            image.path.includes("mov") || image.path.includes("MOV") || 
            image.path.includes("mkv") || image.path.includes("MKV")) {
            const video = document.createElement("video");
            video.src = `https://storage.googleapis.com/bucket_for_h4m/${image.path}`;
            video.onloadeddata = () => resolve(true);
            video.onerror = () => resolve(true);
          } else {
            const img = new Image();
            img.src = `https://storage.googleapis.com/bucket_for_h4m/${image.path}`;
            img.onload = () => resolve(true);
            img.onerror = () => resolve(true);
          }
        });
      });
      Promise.all(promises).then(() => setIsImagesLoading(false));
    }else{
      setIsImagesLoading(false)
    }
  },[imagesToShow])

  const [dateButtons, setDateButtons] = useState<string[]>([]);
  useEffect(() => {
    if (dateButtons.length > 0 && allPages[currentYear]) {
      setPages(allPages[currentYear]);
      setSelectedButton(currentYear);
    }
  }, [allPages, dateButtons.length, currentYear]);
  useEffect(() => {
    const years: string[] = Array.from(
      new Set(
        memories?.map((el: { date: string | number | Date }) =>
          new Date(el.date).getFullYear().toString()
        ) as string
      )
    ).sort();

    const formattedPages: Record<string, any[]> = {};

    memories?.forEach((el: { date: string | number | Date }) => {
      const key = new Date(el.date).getFullYear().toString();
      if (!formattedPages[key]) {
        formattedPages[key] = [];
      }
      formattedPages[key].push(el);
    });

    setAllPages(formattedPages);
    setDateButtons(years);

    if (!years.includes(currentYear)) {
      setCurrentYear(years[0]);
    }
    if (!allPages[currentYear] && years.includes(currentYear)) {
      setCurrentYear(years[0]);
      setCurrentPage(0);
    }
  }, [memories]);

  const handleDateButtonClick = (button: string) => {
    setPages(allPages[button]);
    setCurrentPage(0);
    setCurrentYear(button);
    setSelectedButton(button);
  };
  

  const handleChangePage = (direction: string) => {
    if (direction === "prev" && currentPage === 0) {
      const years = Object.keys(allPages);
      const currentYearIndex = years.findIndex(year => year === currentYear);
      if (currentYearIndex > 0) {
        const prevYear = years[currentYearIndex - 1];
        const prevYearPages = allPages[prevYear];
        setPages(prevYearPages);
        setCurrentPage(prevYearPages.length - 1);
        setCurrentYear(prevYear);
      }
    } else if (direction === "next" && currentPage === pages.length - 1) {
      const years = Object.keys(allPages);
      const currentYearIndex = years.findIndex(year => year === currentYear);
      if (currentYearIndex < years.length - 1) {
        const nextYear = years[currentYearIndex + 1];
        const nextYearPages = allPages[nextYear];
        setPages(nextYearPages);
        setCurrentPage(0);
        setCurrentYear(nextYear);
      }
    } else {
      setCurrentPage(direction === "prev" ? currentPage - 1 : currentPage + 1);
    }
  };
  

  const hebrewRegex = /[\u0590-\u05FF]/;
  const hasHebrewText =
    hebrewRegex.test(pages[currentPage]?.name) ||
    hebrewRegex.test(pages[currentPage]?.location) ||
    pages[currentPage]?.participants?.some((participant: any) =>
      hebrewRegex.test(participant)
    ) ||
    hebrewRegex.test(pages[currentPage]?.description);

  const setMemoryId = (id: string) => {
    setMemoryIdToken(id);
  };

  const handlerShare = (id: string) => {
    setOpenShare(true);
    setGetMemoryID(id);
  };

  return (
    <>
      {pages?.length > 0 && (
        <>
          <div className={styles.buttonPanel}>
            <img
              onClick={() => handlerShare(pages[currentPage]?._id)}
              className={styles.edit}
              src={Share}
              alt="Edit"
            />
            <Link
              onClick={() => setMemoryId(pages[currentPage]?._id)}
              to="/Edit-memory"
            >
              <img className={styles.edit} src={Edit} alt="Edit" />
            </Link>
          </div>
          {memories.length > 0 && (
            <div className={styles.wrapper}>
              <div
                className={`${styles.texts} ${hasHebrewText ? styles.textsHebrew : ""
                  }`}
              >
                <h1 style={{overflow:"hidden", textOverflow:"ellipsis"}}>{pages[currentPage]?.name}</h1>
                <div className={styles.head} style={{overflow:"hidden", textOverflow:"ellipsis"}}>
                  <Moment format="DD.MM.YYYY" >{pages[currentPage]?.date}</Moment> \\{" "}
                  {pages[currentPage]?.location}
                </div>
                {/* <div className={styles.subHead}><b>משתתפים :</b> {pages[page]?.contributor?.map((ele:string,index:number)=>`${index+1}. ${ele} `)}</div> */}
                <div
                  className={styles.subHead}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "right", // Aligns text to the right
                    direction: "rtl"    // Ensures right-to-left text direction
                  }}
                >
                  <b>משתתפים :</b> {pages[currentPage]?.participants?.join(", ")}
                </div>
                <div
                  className={styles.description}
                  style={{wordBreak:"break-word"}}
                  dangerouslySetInnerHTML={{ __html: pages[currentPage]?.description }}
                  
                />
              </div>
              <div className={styles.picture}>
                {isLoading || isImagesLoading ? (
                  <div className={styles.loading}>
                    <TailSpin
                      height="50"
                      width="50"
                      color="#4fa94d"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      visible={true}
                    />
                  </div>
                ) : (
                  <Masonry
                    breakpointCols={{
                      default: 2,
                      700: 1,
                    }}
                    className={styles.myMasonryGrid}
                    columnClassName={styles.myMasonryGridColumn}
                  >
                    {!isImagesLoading && imagesToShow?.map((path, index) => (
                      <div
                        key={index}
                        className={
                          path?.metadata
                            ? styles.myMasonryGridColumnRed
                            : styles.myMasonryGridColumn
                        }
                      >
                        {path?.path?.includes("mp4") ||
                          path?.path?.includes("MP4") ||
                          path?.path?.includes("mov") ||
                          path?.path?.includes("MOV") ||
                          path?.path?.includes("mkv") ||
                          path?.path?.includes("MKV") ? (
                          <>
                            <video
                              onClick={() => openPhoto(path,pages[currentPage]?._id)}
                              src={`https://storage.googleapis.com/bucket_for_h4m/${path.path}`}
                              controls={false}
                            />
                            <button
                              onClick={() => openPhoto(path,pages[currentPage]?._id)}
                              className={styles.playButton}
                            ></button>
                          </>
                        ) : (
                          <img
                            className={styles.images}
                            src={`https://storage.googleapis.com/bucket_for_h4m/${path.path}`}
                            alt=""
                            onClick={() => openPhoto(path,pages[currentPage]?._id)}
                          />
                        )}
                      </div>
                    ))}
                  </Masonry>
                )}
              </div>
            </div>
          )}
        </>
      )}

      <div className={styles.arrowContainer}>
        {!(selectedButton === Object.keys(allPages)[0] && currentPage === 0) && (
          <button
            className={styles.button}
            onClick={() => handleChangePage("prev")}
          >
            <img className={styles.arrowLeft} src={arrow} alt="arrow" />
          </button>
        )}
        {!(selectedButton === Object.keys(allPages).slice(-1)[0] && currentPage === pages.length - 1 ) && (
          <button
            className={styles.button}
            onClick={() => handleChangePage("next")}
          >
            <img className={styles.arrowRight} src={arrow} alt="arrow" />
          </button>
        )}
      </div>

      <div className={styles.buttonContainer}>
        {dateButtons
          .sort((a: string, b: string) => parseInt(a) - parseInt(b))
          .map((button: string, idx: number) => (
            <button
              className={`${styles.button} ${!selectedButton && idx === 0
                ? styles.selected
                : "" || selectedButton === button
                  ? styles.selected
                  : ""
                }`}
              key={idx}
              onClick={() => handleDateButtonClick(button)}
            >
              {button}
            </button>
          ))}
      </div>
    </>
  );
};
