import styles from "./PopupPicture.module.scss";
import { FC, useState, useEffect } from "react";
import arrow from "../../assets/icons/rotate-icon.png";
import close from "../../assets/menu/plus.png";
import { TextField } from "..";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getMemoryIdToken, getTimelineIdToken } from "../../hooks/localstorage";
import { fetchFileMetadata } from "../../store/api/fetchFileMetadata";
import { resetMetadata } from "../../store/feature/scattered/addComment";
import Edit from "../../assets/icons/edit-icon.png";
import { fetchFiles } from "../../store/api/fetchFiles";
import { fetchSingleMemory } from "../../store/api/fetchSingleMemory";
import { fetchGetMemories } from "../../store/api/fetchGetMemories";
import { fetchGetDocument } from "../../store/api/fetchGetDocument";
import { fetchSingleDocument } from "../../store/api/fetchSingleDocument";

interface IPopupPicture {
  getPhoto: any;
  setOpenPopup?: any;
  scattered?: boolean;
}

export const PopupPicture: FC<IPopupPicture> = ({
  getPhoto,
  setOpenPopup,
  scattered = false,
}) => {
  const dispatch = useAppDispatch();
  const [rotatePicture, setRotatePicture] = useState(true);
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState<string>("");
  const [metadata, setMetaData] = useState<string>("");
  const [path] = useState<string>(getPhoto?.path);
  const [address] = useState<string>(getPhoto?.metadata?.address);
  // const [isRotateVisible, setIsRotateVisible] = useState<boolean>(false);
  const id = scattered ? getTimelineIdToken() : getMemoryIdToken();

  const { data, error } = useAppSelector((state) => state.fileMetadataReducer);
  const { dataSingle } = useAppSelector((state) => state.singleTimelineReducer);
  const imageUrl = `https://storage.googleapis.com/bucket_for_h4m/${getPhoto?.path}`;

  const handlerClick = async () => {
    try {
      await dispatch(
        fetchFileMetadata({ id, path, address, additionalText: text })
      );
      if (scattered) {
        dispatch(fetchFiles(id));
      } else {
        dispatch(fetchSingleMemory(id));
        dispatch(fetchGetMemories(getTimelineIdToken()));
        dispatch(fetchSingleDocument(id));
        dispatch(fetchGetDocument(getTimelineIdToken()));
      }
      setMetaData(text);
      setOpenPopup(false);
    } catch (error) {
      console.log("Error updating comment:", error);
    }
  };

  function getImgSize(imgSrc: string) {
    var newImg = new Image();

    newImg.onload = function () {
      var height = newImg.height;
      var width = newImg.width;
      if (height <= 1000 && width <= 1000) {
        // setIsRotateVisible(true);
      }
    };
    newImg.src = imgSrc;
  }

  useEffect(() => {
    dispatch(resetMetadata());
    setText(getPhoto?.metadata?.additionalText || "");
    setMetaData(getPhoto?.metadata?.additionalText || "");
    // getImgSize(imageUrl);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {rotatePicture ? (
          getPhoto?.path?.includes("mp4") ||
          getPhoto?.path?.includes("MP4") ||
          getPhoto?.path?.includes("mov") ||
          getPhoto?.path?.includes("MOV") ||
          getPhoto?.path?.includes("mkv") ||
          getPhoto?.path?.includes("MKV") ? (
            <video className={styles.video} controls>
              <source src={imageUrl} type="video/mp4" />
            </video>
          ) : (
            <img alt={getPhoto.id} className={styles.images} src={imageUrl} />
          )
        ) : (
          <div className={styles.block}>
            {dataSingle?.role === "admin" && (
              <img alt="edit" src={Edit} onClick={() => setEdit(!edit)} />
            )}
            {/* {getPhoto.deletePermission && <img src={Edit} onClick={() => setEdit(!edit)} />} */}

            <div className={styles.blockWrapper}>
              <p>{getPhoto?.metadata?.address}</p>
              <p>{getPhoto?.metadata?.date}</p>
              <p>{metadata}</p>
              <p className={styles.errors}>{error && error}</p>
              <p className={styles.done}>{data && "Change has been added"}</p>
              <p>{data}</p>
              {edit && (
                <div className={styles.comments}>
                  <TextField
                    label=""
                    type="text"
                    value={text}
                    setValue={setText}
                    sx={{ width: "250px" }}
                  />
                  <button onClick={handlerClick}>Update Comment</button>
                </div>
              )}
            </div>
          </div>
        )}
        {/* {isRotateVisible && ( */}
          <img
            onClick={() => setRotatePicture(!rotatePicture)}
            className={styles.rotate}
            src={arrow}
            alt="arrow"
          />
        {/* )} */}
        <img
          alt="closing popup"
          src={close}
          className={styles.close}
          onClick={() => setOpenPopup(false)}
        />
      </div>
    </div>
  );
};
