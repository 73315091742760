import { Backgrounds, NavigateBar, PopupPicture } from "../../components";
import { Layout } from "../../utils/Layout";
import Background from "../../assets/background/white-background.png";
import Download from "../../assets/icons/download.png";
import { EditContainer } from "./ui/EditContainer";
import { useEffect, useState } from "react";
import { UploadContainer } from "./ui/uploadContainer";
import { useAppSelector } from "../../hooks/redux";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

export const EditTimeline = () => {
  const [display, setDisplay] = useState(2);
  const [openPopup, setOpenPopup] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [visualCarousel, setVisualCarousel] = useState<any>([]);
  const [additionalPicture, setAdditionalPicture] = useState<any>([]);
  const [getPhoto, setGetPhoto] = useState({});
  const [closeProgressBar, setCloseProgressBar] = useState(false);
  const [step, setStep] = useState(1);
  const { data, isLoading } = useAppSelector(
    (state) => state.singleMemoryReducer
  );
  const updatedMemory = useSelector((state: RootState) => state.updateMemoriesReducer);

  useEffect(()=>{
    setFiles([...files,...updatedMemory.scatteredFiles])
  },[updatedMemory.scatteredFiles]);
  
  return (
    <Layout>
      <NavigateBar />
      <Backgrounds background={Background} logo={Download}>
        {display === 1 ? (<UploadContainer mode="edit" setDisplay={setDisplay} setClose={setCloseProgressBar} files={files} setFiles={setFiles} data={data} />)
          : display === 2 ? (<>
            <EditContainer setOpenPopup={setOpenPopup} setGetPhoto={setGetPhoto} setDisplay={setDisplay} files={files} setFiles={setFiles} />
            {openPopup && <PopupPicture getPhoto={getPhoto} setOpenPopup={setOpenPopup} />}
          </>) : null}
      </Backgrounds>
    </Layout>
  );
};