import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { IFiles } from "./types";

  export const fetchDocumentUpdateFiles = createAsyncThunk<IFiles, { formData: FormData, ids: string | null }, { rejectValue: string }>(
    "updateFilesMemories/fetchDocumentUpdateFiles",
    async ({ formData, ids }, thunkAPI) => {
      try {
        const config = {
          headers: { "Content-Type": "multipart/form-data" },
        };
  
        const response = await axiosConfig.post<IFiles>(`/document/updateFiles/${ids}`, formData, config);
        return response.data;
      } catch (error: any) {
        return thunkAPI.rejectWithValue(
          error.response?.data?.message || error.response.status===500 ? "File is too large" : "failed upload files"
        );
      }
    }
  );