import styles from "./UploadContainer.module.scss";
import photo from "../../../../assets/icons/photo-icon.png";
import { Link, useNavigate } from "react-router-dom";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { MultiUpload } from "../MultiUpload";
import { Button } from "../../../../components";

interface IUploadContainer {
  setDisplay: Dispatch<SetStateAction<number>>;
  setClose: Dispatch<SetStateAction<boolean>>;
  data?: any;
  files: any;
  setFiles: any;
  mode:string;
}

export const UploadContainer: FC<IUploadContainer> = ({
  setDisplay,
  setClose,
  data,
  files,
  setFiles,
  mode
}) => {
  const handlerClick = () => {
    setDisplay(2);
    if (data?.name) {
      setClose(true);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1>Upload Media</h1>

        <div className={styles.line}>
          <div className={styles.element}>
            <div className={styles.block}>
              <MultiUpload files={files} setFiles={setFiles} />
            </div>
            <p>Device</p>
          </div>

          <div className={styles.element}>
            <div className={styles.block}>
              <Link to="/Scattered/edit">
                <img src={photo} alt="photo" />
              </Link>
            </div>
            <p>Add from scattered pictures</p>
          </div>
        </div>

        <Button
          title="Next"
          action={handlerClick}
          sx={{
            display: "flex",
            justifyContent: "right",
            width: "100%",
            position: "absolute",
            right: "30px",
            bottom: "30px",
          }}
        />
      </div>
    </div>
  );
};
