import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  DateField,
  FieldDescription,
  MultiTextSelect,
  SelectText,
  SmallRadio,
  TextFieldLine,
  UploadImages,
} from "../../../../components";
import styles from "./EditContainer.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import moment from "moment";
import { fetchSingleMemory } from "../../../../store/api/fetchSingleMemory";
import {
  getMemoryIdToken,
  getNameToken,
  getTimelineIdToken,
} from "../../../../hooks/localstorage";
import { Carousel } from "../../../../components/Carousel";
import { fetchUpdateMemories } from "../../../../store/api/fetchUpdateMemories";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { fetchUpdateFilesMemories } from "../../../../store/api/fetchUpdateFilesMemories";
import { resetUpdateMemoryData } from "../../../../store/feature/memory/updateMemories";
import { resetUpdateMemoryFiles } from "../../../../store/feature/memory/updateFilesMemories";
import { fetchDeleteMemories } from "../../../../store/api/fetchDeleteMemories";
import { resetDeleteMemoryData } from "../../../../store/feature/memory/deleteMemories";
import { RootState } from "../../../../store";
import { fetchSingleTimeline } from "../../../../store/api/fetchSingleTimeline";
import { resetUpdateMemoryDataSign } from "../../../../store/feature/memory/updateMemoriesForSignup";
import { useSelector } from "react-redux";
import { resetUpdateMemoryFiless } from "../../../../store/feature/memory/updateFilesMemoriess";

interface IEditContainer {
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setGetPhoto: (value: {}) => void;
  setDisplay: any;
  files:any;
  setFiles:any;
}

export const EditContainer: FC<IEditContainer> = ({
  setOpenPopup,
  setGetPhoto,
  setDisplay,
  files,
  setFiles
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const id = getMemoryIdToken();
  const timeline = getTimelineIdToken();
  const nameUser = getNameToken();

  const [cv, setCv] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [date, setDate] = useState<any>("");
  const [location, setLocation] = useState<string>("");
  const [participants, setParticipants] = useState<any>([]);
  const [description, setDescription] = useState<string>("");
  // const [files, setFiles] = useState<any>(null);
  const [privacy, setPrivacy] = useState<string>("");
  const [bulkPageFiles, setBulkPageFiles] = useState<any>([]);
  const [memoryFiles, setMemoryFiles] = useState<any>([]);
  const [visualCarousel, setVisualCarousel] = useState<any>([]);
  const [additionalPicture, setAdditionalPicture] = useState<any>([]);
  const [isRequestSent, setIsRequestSent] = useState(false);

  const { data, isLoading } = useAppSelector(
    (state) => state.singleMemoryReducer
  );
  const { update, Loading, errors } = useAppSelector(
    (state) => state.updateMemoriesReducer
  );
  const { responseData, Load, err } = useAppSelector(
    (state) => state.updateFilesMemoriesReducer
  );
  const { result, isLoad, er } = useAppSelector(
    (state) => state.deleteMemoriesReducer
  );
  const { dataSingle } = useAppSelector(
    (state: RootState) => state.singleTimelineReducer
  );
  const { updateSignUp } = useAppSelector(
    (state) => state.updateMemoriesForSignupReducer
  );
  const updatedMemory = useSelector((state: RootState) => state.updateMemoriesReducer);

  useEffect(() => {
    if (files != null) {
      setVisualCarousel([...memoryFiles, ...bulkPageFiles, ...files]);
    }
  }, [files]);

  useEffect(() => {
    dispatch(fetchSingleTimeline(timeline));
  }, [dispatch]);

  useEffect(() => {
    if (update === "OK" && files === null) {
      dispatch(resetUpdateMemoryData());
      navigate("/Timeline");
    } else if (update === "OK" && files !== null && !isRequestSent) {
      const formData = new FormData();
      files.forEach((file: string | Blob) => {
        formData.append("files", file);
      });

      dispatch(fetchUpdateFilesMemories([formData, id || ""]));
      setIsRequestSent(true);
    } else if (responseData === "OK" && isRequestSent) {
      dispatch(resetUpdateMemoryData());
      dispatch(resetUpdateMemoryFiles());
      navigate("/Timeline");
    }
  }, [update, responseData, files, isRequestSent, navigate, dispatch, id]);

  useEffect(() => {
    if (updateSignUp?.id !== null && files === null) {
      dispatch(resetUpdateMemoryDataSign());
      navigate("/Timeline");
    } else if (updateSignUp?.id !== null && files !== null && !isRequestSent) {
      const formData = new FormData();
      files.forEach((file: string | Blob) => {
        formData.append("files", file);
      });
      formData.append("timeline", timeline || "");
      dispatch(fetchUpdateFilesMemories([formData, id || ""]));
      setIsRequestSent(true);
    } else if (responseData === "OK" && isRequestSent) {
      dispatch(resetUpdateMemoryDataSign());
      dispatch(resetUpdateMemoryFiles());
      navigate("/Timeline");
    }
  }, [
    updateSignUp?.id,
    responseData,
    files,
    isRequestSent,
    navigate,
    dispatch,
    id,
  ]);

  useEffect(() => {
    if (result === "OK") {
      navigate("/Timeline");
      dispatch(resetDeleteMemoryData());
    }
  }, [result, navigate, dispatch]);

  const updateDesc = `${`<p>${description}</p> `} `

  const handlerUpdate = () => {
    dispatch(
      fetchUpdateMemories({
        cv,
        name,
        date,
        location,
        participants,
        description: updateDesc,
        privacy,
        bulkPageFiles:
          additionalPicture.length > 0 ? additionalPicture : [...bulkPageFiles],
        memoryFiles: additionalPicture.length > 0 ? null : memoryFiles,
        id,
      })
    );
    dispatch(resetUpdateMemoryFiless())
  };

  const handlerDelete = () => {
    dispatch(fetchDeleteMemories(id));
  };

  useEffect(() => {
    dispatch(fetchSingleMemory(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (data) {
      const {
        cv,
        name,
        date,
        location,
        description,
        participants,
        privacy,
        bulkPageFiles: bulkPageFilesArr,
        memoryFiles,
      } = data;
      setCv(cv);
      setName(name);
      setLocation(location);
      setParticipants(participants);
      // setDescription(dataSingle?.role === "admin" ? description : "");
      setDescription(description);
      setPrivacy(privacy);

      const formattedDate = moment.utc(date).format("YYYY-MM-DD");
      setDate(formattedDate);

      const mergedPicture = [...bulkPageFilesArr, ...memoryFiles, ...files];
      setVisualCarousel(mergedPicture);

      if(updatedMemory.scatteredFiles) {
        setBulkPageFiles([...bulkPageFilesArr, ...updatedMemory.scatteredFiles]);
      } else {
        setBulkPageFiles(bulkPageFilesArr);
      }
     
      setMemoryFiles(memoryFiles);
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.loading}>
        {(isLoading || Loading || Load || isLoad) && (
          <TailSpin
            height="50"
            width="50"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        )}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.texts}>
          <div className={styles.column}>
            <SelectText
              label="Master headlines(CV) :"
              value={cv}
              setValue={setCv}
              sx={{ width: "300px" }}
            />
            <TextFieldLine
              label="Name :"
              type="text"
              value={name}
              setValue={setName}
              sx={{ width: "300px" }}
            />
            <DateField
              label="Date :"
              width="300px"
              value={date}
              setValue={setDate}
            />
            <TextFieldLine
              label="Location :"
              type="text"
              value={location}
              setValue={setLocation}
              sx={{ width: "300px" }}
            />
            <MultiTextSelect
              label="Participants :"
              value={participants}
              setValue={setParticipants}
              sx={{ width: "300px" }}
            />
            <FieldDescription
              label="Description :"
              value={description}
              setValue={setDescription}
              sx={{ width: "300px" }}
            />
          </div>
          <div className={styles.radio}>
            <SmallRadio setPrivacy={setPrivacy} privacy={privacy} />
          </div>
          <div className={styles.carousel}>
            <p>Additional media :</p>
            <Carousel
              setOpenPopup={setOpenPopup}
              setGetPhoto={setGetPhoto}
              picture={visualCarousel}
              setPicture={setVisualCarousel}
              setAdditionalPicture={setAdditionalPicture}
            />
          </div>
        </div>
        <div className={styles.texts}>
          <UploadImages
            image={files}
            setImage={setFiles}
            preview={bulkPageFiles}
            setDisplay={setDisplay}
            isUpdate={true}
          />
        </div>
      </div>
      <span
        style={{
          display: "flex",
          justifyContent: "right",
          color: "red",
          fontSize: "13px",
          height: "20px",
        }}
      >
        {errors || err || err}
      </span>
      <div style={{ display: "flex", justifyContent: "right" }}>
        {dataSingle?.role === "admin" ? (
          <Button
            sx={{ marginRight: "50px" }}
            title="Delete"
            action={handlerDelete}
          />
        ) : null}
        <Button title="Update" action={handlerUpdate} />
      </div>
    </div>
  );
};
