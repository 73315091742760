import { TailSpin } from "react-loader-spinner";
import styles from "./UploadImages.module.scss";
import { FC, useState, useRef } from "react";
import Background from "../../../../assets/background/basic.png";

interface IUploadImages {
  setImage: any;
  image?: any;
  preview?: any;
  loading?: boolean;
}

export const UploadImages: FC<IUploadImages> = ({
  setImage,
  image,
  preview,
  loading,
}) => {
  const [imageUrl, setImageUrl] = useState<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImageUrl(null);
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={styles.wrapper}>
      {loading ? (
        <TailSpin
          height="30"
          width="30"
          color="#4fa94d"
          ariaLabel="tail-spin-loading"
          radius="1"
          visible={true}
          wrapperStyle={{ display: "flex", justifyContent: "right" }}
        />
      ) : (
        <>
          {!imageUrl ? (
            <>
              <div className={styles.block} onClick={handleImageClick}>
                <img
                  src={preview ? `https://storage.googleapis.com/bucket_for_h4m/${preview}` : Background}
                  className={styles.viewBlock}
                  alt=""
                />
                <label htmlFor="upload" >Edit picture</label>
                <input
                  type="file"
                  name=""
                  id="upload"
                  onChange={handleImageChange}
                  ref={fileInputRef}
                  className={styles.hiddenInput}
                />
              </div>
            </>
          ) : (
            <div className={styles.block}>
              {imageUrl ? (
                <div style={{ textAlign: "center" }}>
                  <img src={imageUrl} alt={image.name} />
                  <button onClick={handleRemoveImage}>Delete</button>
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    name=""
                    id="upload"
                    onChange={handleImageChange}
                    ref={fileInputRef}
                  />
                  <label htmlFor="upload">Add picture</label>
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
