import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAuth } from "./types";
import {axiosConfig} from "../axios.config";

export const fetchAuth = createAsyncThunk(
  "auth/fetchAuth",
  async (body: IAuth, thunkAPI) => {
    try {
      const response = await axiosConfig.post<IAuth>(
        // `https://back.hunt4memories.com/api/user/login`,
        `user/login`,
        body
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Login failed"
      );
    }
  }
);
