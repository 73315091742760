import { FC, useRef, useState } from "react";
import styles from "./MultiUpload.module.scss";
import { useAppDispatch } from "../../../../hooks/redux";

interface IMultiUpload {
  files:any;
  setFiles:any;
}

export const MultiUpload : FC<IMultiUpload> = ({files,setFiles }) => {


  const dispatch = useAppDispatch();
  const [imageUrls, setImageUrls] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<any>([]);
  const [imageUrl, setImageUrl] = useState<any>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filesDevice = e.target.files;


    if (filesDevice) {
      const fileList = Array.from(filesDevice);
      if(files.length > 0){
        setFiles([...files,...fileList]);
      }else{
        setFiles([...fileList])
      }
    }
  };


  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.block}>
          <input
            type="file"
            name=""
            id="upload"
            onChange={handleImageChange}
            ref={fileInputRef}
            multiple
          />
        </div>
      </div>
    </div>
  );
};
