import { Select, SelectProps } from "antd";
import styles from "./MultiTextSelect.module.scss";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getTimelineIdToken } from "../../hooks/localstorage";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { fetchGetParticipants } from "../../store/api/fetchGetParticipants";

interface IMultiTextSelect {
  label?: string;
  value: any;
  setValue: Dispatch<SetStateAction<string[]>>;
  sx: {};
  centerMode?: any;
  onChange?: any;
  participantsData?:string[];
}

interface Record {
  value: string; 
  name:string;
}

export const MultiTextSelect: React.FC<IMultiTextSelect> = ({
  label,
  value,
  setValue,
  sx,
  centerMode,
  onChange,
  participantsData
}) => {
  const dispatch = useAppDispatch();
  const id = getTimelineIdToken();

  const [uniqueRecords, setUniqueRecords] = useState<Record[]>([]);
  const gridTemplateColumns: any = centerMode ? null : "180px auto";

  const { data } = useAppSelector((state) => state.getParticipantsReducer);

  useEffect(()=>{
    if(participantsData){
      const uniqueRecords = participantsData.map((participant)=>({name:participant,value:participant}))
      setUniqueRecords(uniqueRecords)
    }
  },[participantsData])

  useEffect(() => {
    if(!participantsData){
      const uniqueRecords = data.reduce<Record[]>((acc:any, current:any) => {
        const x = acc.find((item:Record) => item.value === current.value);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      setUniqueRecords(uniqueRecords)
    }
  },[data])
  
  useEffect(() => {
    dispatch(fetchGetParticipants(id));
  }, [dispatch]);

  const handleChange = (value: string[]) => {
    setValue(value);
  };

  return (
    <div
      className={styles.wrapper}
      style={{
        display: centerMode ? "block" : "grid",
        gridTemplateColumns,
      }}
    >
      <label htmlFor={label}>{label}</label>
      <Select
        mode="tags"
        onChange={onChange ? onChange :handleChange}
        tokenSeparators={[","]}
        options={uniqueRecords}
        value={value}
        className="my-select"
        style={sx}
      />
    </div>
  );
};
