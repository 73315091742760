import styles from "./UploadImages.module.scss";
import { FC, useState, useRef } from "react";

interface IUploadImages {
  setImage: any;
  image?: any;
  preview?: any;
  setDisplay?:any;
  isUpdate?: boolean;
}

export const UploadImages: FC<IUploadImages> = ({
  setImage,
  image,
  preview,
  setDisplay,
  isUpdate
}) => {
  const [imageUrl, setImageUrl] = useState<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length) {
      const mediaFiles = files.filter((file) => file.type.startsWith("image/") || file.type.startsWith("video/"));
      setImage(mediaFiles);
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result as string);
      };
      reader.readAsDataURL(mediaFiles[0]);
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImageUrl(null);
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className={styles.wrapper}>
      {preview?.length ? (
        <>
          {preview[0].path ? (
            <div className={styles.blocks} onClick={handleImageClick}>
              {preview[0]?.path?.includes("mp4") ||
              preview[0]?.path?.includes("MP4") ||
              preview[0]?.path?.includes("mov") ||
              preview[0]?.path?.includes("MOV") ||
              preview[0]?.path?.includes("mkv") ||
              preview[0]?.path?.includes("MKV") ? (
                <video>
                  <source
                    src={`https://storage.googleapis.com/bucket_for_h4m/${preview[0].path}`}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  src={`https://storage.googleapis.com/bucket_for_h4m/${preview[0].path}`}
                  alt="images"
                />
              )}

              {!isUpdate?(<><input
                type="file"
                name=""
                id="upload"
                onChange={handleImageChange}
                ref={fileInputRef}
                className={styles.hiddenInput}
                multiple
              />
              <label htmlFor="upload" style={{ pointerEvents: 'none' }}>Add picture</label></>):(<>
              <span onClick={()=>setDisplay(1)}>Add picture</span></>)}
            </div>
          ) : preview[0]?.type === "video/mp4" ? (
            <div className={styles.videoUrl}>
              <video className={styles.blocks}>
                <source
                  src={URL.createObjectURL(preview[0])}
                  type="video/mp4"
                />
              </video>
              {!isUpdate?(<label htmlFor="upload" >Add picture</label>):(<span onClick={()=>setDisplay(1)}>Add picture</span>)}
              
            </div>
          ) : (
            <div className={styles.videoUrl}>
              <div className={styles.blocks}>
                <img src={URL.createObjectURL(preview[0])} />
              </div>
              {!isUpdate?(<label htmlFor="upload" >Add picture</label>):(<span onClick={()=>setDisplay(1)}>Add picture</span>)}
            </div>
          )}
        </>
      ) : (
        <div className={styles.blocks}>
          {imageUrl ? (
            <div>
              <img src={imageUrl} alt={image.name} />
              <button onClick={handleRemoveImage}>Delete</button>
            </div>
          ) : (
            <>
            {!isUpdate?(<><input
                type="file"
                name=""
                id="upload"
                onChange={handleImageChange}
                ref={fileInputRef}
                multiple
              />
              <label htmlFor="upload" >Add picture</label></>):<span onClick={()=>setDisplay(1)}>Add picture</span>}

            </>
          )}
        </div>
      )}
    </div>
  );
};
