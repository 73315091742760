import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";
import { IFiles } from "./types";

export const fetchRemoveFiles = createAsyncThunk(
  "updateFiles/fetchRemoveFiles",
  async ({ id, ...body }: { id: string | null } & IFiles, thunkAPI) => {
    try {
      const response = await axiosConfig.post<IFiles>(`/timeline/removeFiles/${id}`,{bulkpageFiles:body.bulkPageFiles} );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "failed remove files"
      );
    }
  }
);
