import styles from "./TextField.module.scss";
import { Dispatch, FC, SetStateAction } from "react";
import MaskedInput from "react-input-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";

interface ITextField {
  label: string;
  type: string;
  sx?: {};
  value: string | undefined;
  setValue: Dispatch<SetStateAction<string>>;
  useMask?: boolean;
}

export const TextField: FC<ITextField> = ({
  label,
  type,
  sx,
  value,
  setValue,
  useMask = false,
}) => {

  const inputElement = useMask ? (
    <MaskedInput
      mask="+\972999999999"
      // mask="+\972#########"
      value={value}
      onChange={(e: { target: { value: SetStateAction<string> } }) =>
        setValue(e.target.value)
      }
      id={label}
      type={type}
    />
  ) : (
    <input
      value={value}
      onChange={(e) => setValue(e.target.value)}
      id={label}
      type={type}
    />
  );

  return (
    <div style={sx} className={styles.wrapper}>
      <label htmlFor={label}>{label}</label>
      {inputElement}
    </div>
  );
};
