import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUpdateMemories } from "../../../api/fetchUpdateMemories";
import { IMemoryPhotos } from "../createMemories";

interface UserState {
  update: string;
  Loading: boolean;
  errors: null;
  scatteredFiles: any[];
}

const initialState: UserState = {
  update: "",
  Loading: false,
  errors: null,
  scatteredFiles:[]
};

export const updateMemoriesReducer = createSlice({
  name: "updateMemories",
  initialState,
  reducers: {
    resetUpdateMemoryData: (state) => {
      state.update = initialState.update;
      state.scatteredFiles = initialState.scatteredFiles;
    },
    updateMemoryFiles: (state, action: PayloadAction<IMemoryPhotos[]>) => {
      state.scatteredFiles = action.payload;
    }
  },
  extraReducers: {
    [fetchUpdateMemories.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.Loading = false;
      state.errors = null;
      state.update = action.payload;
    },
    [fetchUpdateMemories.pending.type]: (state) => {
      state.Loading = true;
    },
    [fetchUpdateMemories.rejected.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.Loading = false;
      state.errors = action.payload;
    },
  },
});

export const { resetUpdateMemoryData, updateMemoryFiles } = updateMemoriesReducer.actions;

export default updateMemoriesReducer.reducer;
