import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchDeleteDocument } from "../../../api/fetchDeleteDocument";

interface UserState {
  result: string;
  isLoad: boolean;
  er: null | any;
}

const initialState: UserState = {
  result: "",
  isLoad: false,
  er: null,
};

export const deleteDocumentReducer = createSlice({
  name: "deleteDocument",
  initialState,
  reducers: {
    resetDeleteDocumentData: (state) => {
      state.result = initialState.result;
      state.er = initialState.er;
    },
  },
  extraReducers: {
    [fetchDeleteDocument.fulfilled.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoad = false;
      state.er = null;
      state.result = action.payload;
    },
    [fetchDeleteDocument.pending.type]: (state) => {
      state.isLoad = true;
    },
    [fetchDeleteDocument.rejected.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoad = false;
      state.er = action.payload;
    },
  },
});

export const { resetDeleteDocumentData } = deleteDocumentReducer.actions;

export default deleteDocumentReducer.reducer;
