import { FC, useCallback, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Select } from "antd";
import styles from "./PopupShare.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import {
  getNameToken,
  getTimelineIdToken,
  getTimelineNameToken,
} from "../../../../hooks/localstorage";
import { fetchSingleTimeline } from "../../../../store/api/fetchSingleTimeline";
import Copy from "../../../../assets/icons/copy-icon.png";
import Whatsapp from "../../../../assets/icons/whatsapp-icon.png";
import Minus from "../../../../assets/icons/minus.png";
import Plus from "../../../../assets/menu/create.png";
import { Button } from "../../../../components";
import { ParticipantChanges } from "../../../CreateMemory/ui/ParticipantChanges";
import { fetchSingleMemory } from "../../../../store/api/fetchSingleMemory";

interface IPopupShare {
  setOpenShare?: any;
  getMemoryId?: any;
}

export const PopupShare: FC<IPopupShare> = ({ setOpenShare, getMemoryId }) => {
  const dispatch = useAppDispatch();
  const id = getTimelineIdToken();
  const admin = getNameToken();
  const timelineName = getTimelineNameToken();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [swap, setSwap] = useState(false);
  const [addParticipant, setAddParticipant] = useState(false);
  const [choise, setChoise] = useState("");

  const { dataSingle } = useAppSelector((state) => state.singleTimelineReducer);
  const { data } = useAppSelector((state) => state.singleMemoryReducer);

  const options = dataSingle?.timeline?.participants
    .filter((participant: any) => {
      if (data?.privacy === "private") {
        return participant.role !== "friend";
      } else {
        return true;
      }
    })
    .map((participant: any) => ({
      label: participant.name,
      value: participant.phone,
    }));


  useEffect(() => {
    dispatch(fetchSingleMemory(getMemoryId));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSingleTimeline(id));
  }, [dispatch, addParticipant]);

  const handleChange = (value: string) => {
    const selectedParticipant = options?.find(
      (participant: any) => participant.value === value
    );
    setName(selectedParticipant?.label || "");
    setPhone(selectedParticipant?.value || "");
    setChoise(value);
  };

  const handleCopy = () => {
    let newWindow: any = window.open("", "");
    // newWindow.location.href = `https://wa.me/${phone}?text=${`Hi ${name} is inviting you to assist in completing the memory in https://hunt4memories.com/timeline/${id}/${getMemoryId}/${name} Timeline`}`;
    // newWindow.location.href = `https://wa.me/${phone}?text=${`Hi, ${admin} is inviting you to assist in completing a memory in ${timelineName} Time line. https://hunt4memories.com/timeline/${id}/${getMemoryId}/${name} `}`;
    // newWindow.location.href = `https://wa.me/${phone}?text=${`Hi, ${admin} inviting you to view a memory ${data?.name} : https://hunt4memories.com/timeline/${id}/${getMemoryId}/${name} `}`;
    newWindow.location.href = `https://wa.me/${phone}?text=${`Hi, I want to invite you to assist me editing the following memory ${data?.name} : https://hunt4memories.com/timeline/${id}/${getMemoryId}/${name} `}`;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.block}>
          <p
            className={styles.closePopup}
            onClick={() => setAddParticipant(!addParticipant)}
          >
            {addParticipant ? (
              <img src={Minus} alt="minus" />
            ) : (
              <img src={Plus} alt="plus" />
            )}
          </p>
          {addParticipant ? (
            <div className={styles.participantWrapper}>
              <ParticipantChanges />
            </div>
          ) : (
            <div className={styles.blockWrapper}>
              <h1>Share this memory with</h1>
              <div className={styles.memberWrapper}>
                <p>From Contacts:</p>
                <Select
                  onChange={handleChange}
                  options={options}
                  className="my-select"
                  style={{
                    width: "100%",
                  }}
                />
              </div>
              {choise && (
                <div className={styles.socialWrapper}>
                  <p>Share Via:</p>
                  <div className={styles.socialContent}>
                    <div className={styles.whatsapp} onClick={handleCopy}>
                      <img src={Whatsapp} alt="Copy" />
                    </div>
                    <CopyToClipboard
                      text={`https://hunt4memories.com/timeline/${id}/${getMemoryId}/${name}`}
                      onCopy={() => setSwap(!swap)}
                    >
                      <img className={styles.copy} src={Copy} alt="Copy" />
                    </CopyToClipboard>
                    <span className={styles.message}>{swap && "copied"}</span>
                  </div>
                </div>
              )}

              <Button
                title="Close"
                action={() => setOpenShare(false)}
                sx={{ position: "absolute", right: "20px", bottom: "20px" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
