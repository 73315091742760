import styles from "./Scattered.module.scss";
import {
  Backgrounds,
  Button,
  NavigateBar,
  PopupPicture,
} from "../../components";
import { Layout } from "../../utils/Layout";
import Background from "../../assets/background/white-background.png";
import Download from "../../assets/icons/download.png";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { FC, useEffect, useState } from "react";
import { fetchFiles } from "../../store/api/fetchFiles";
import { getSuperUserToken, getTimelineIdToken } from "../../hooks/localstorage";
import { PicturePlace } from "./ui/PicturePlace";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { fetchSingleTimeline } from "../../store/api/fetchSingleTimeline";

export const Scattered : FC<{mode?:string}> = ({mode}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [openGridPicture, setOpenGridPicture] = useState(false);
  const [getPhoto, setGetPhoto] = useState({});
  const { data, isLoading } = useAppSelector((state) => state.filesReducer);
  const { result } = useAppSelector((state) => state.createMemoriesReducer);
  const { data: removeData } = useAppSelector((state) => state.removeFilesReducer);
  const [isImagesLoading, setIsImagesLoading] = useState(false);
  const superUser = getSuperUserToken()
  const id = getTimelineIdToken();
  const { dataSingle, isLoad } = useAppSelector(
    (state) => state.singleTimelineReducer
  );

  useEffect(() => {
    dispatch(fetchSingleTimeline(id));
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchFiles(id));
  }, [dispatch,removeData]);

  const redirectUpload = () => {
    navigate("/Scattered-upload");
  };

  const redirectCreate = () => {
    navigate("/Create-memory");
  };

  const redirectUpdate = () => {
    navigate("/Edit-memory");
  };


  useEffect(()=>{
    if(data && data.length){
      setIsImagesLoading(true)
      const promises = data.map((image) => {
        return new Promise((resolve) => {
          if (image.path.includes("mp4") || image.path.includes("MP4") || 
            image.path.includes("mov") || image.path.includes("MOV") || 
            image.path.includes("mkv") || image.path.includes("MKV")) {
            const video = document.createElement("video");
            video.src = `https://storage.googleapis.com/bucket_for_h4m/${image.path}`;
            video.onloadeddata = () => resolve(true);
            video.onerror = () => resolve(true);
          } else {
            const img = new Image();
            img.src = `https://storage.googleapis.com/bucket_for_h4m/${image.path}`;
            img.onload = () => resolve(true);
            img.onerror = () => resolve(true);
          }
        });
      });
      Promise.all(promises).then(() => setIsImagesLoading(false));
    }
  },[data])

  return (
    <Layout>
      <NavigateBar />
      <Backgrounds background={Background} logo={Download}>
        <div className={styles.wrapper}>
          {isLoading || isImagesLoading ? (
            <div className={styles.loader}>
              <TailSpin
                height="50"
                width="50"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                visible={true}
              />
            </div>
          ) : (
            <PicturePlace
              setOpenPopup={setOpenPopup}
              setGetPhoto={setGetPhoto}
              data={data}
              openGridPicture={openGridPicture}
              setOpenGridPicture={setOpenGridPicture}
              result={result}
              mode={mode || ''}
            />
          )}
        </div>
        {openPopup && (
          <PopupPicture getPhoto={getPhoto} setOpenPopup={setOpenPopup} scattered={true} />
        )}
        <div className={styles.buttons}>
          { !mode ?
            (<Button
              sx={{ marginRight: "40px" }}
              title="Build memory"
              action={redirectCreate}
            />) : null
          }
          { mode === "edit" && 
            <Button
              sx={{ marginRight: "40px" }}
              title="Update memory"
              action={redirectUpdate}
            />
          }
          { !mode &&
            <Button
              sx={{ marginRight: "40px" }}
              title="Add photo"
              action={redirectUpload}
            />
          }
          <Button title="View all" action={() => setOpenGridPicture(true)} />
        </div>
      </Backgrounds>
    </Layout>
  );
};
