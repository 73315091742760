import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchGetAllParticipants } from "../../../api/fetchAllTimelineParticipants";

interface UserState {
  data: boolean;
  isLoading: boolean;
  error: string;
}

const initialState: UserState = {
  data: false,
  isLoading: false,
  error: "",
};

export const getAllParticipantsReducer = createSlice({
  name: "getAllParticipants",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGetAllParticipants.fulfilled.type]: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isLoading = false;
      state.error = "";
      state.data = action.payload;
    },
    [fetchGetAllParticipants.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchGetAllParticipants.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default getAllParticipantsReducer.reducer;
