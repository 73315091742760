import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";

export const fetchDeleteDocument = createAsyncThunk(
  "deleteDocuments/fetchDeleteDocument",
  async (id: string | null, thunkAPI) => {
    try {
      const response = await axiosConfig.post<string>(`/document/delete/${id}`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "failed delete document"
      );
    }
  }
);
