import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  categoryData: string;
  participantData: string[];
  locationData: string;
  dateData: string;
  isLoading: boolean;
  error: string;
  nameData: string;
}

const initialState: UserState = {
  categoryData: "",
  participantData: [],
  locationData: "",
  dateData: "",
  nameData: "",
  isLoading: false,
  error: "",
};

export const filterHubReducer = createSlice({
  name: "filterHub",
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.categoryData = initialState.categoryData;
      state.participantData = initialState.participantData;
      state.locationData = initialState.locationData;
      state.dateData = initialState.dateData;
    },
    FilterCategory: (state, action: PayloadAction<string>) => {
      state.categoryData = action.payload;
    },
    FilterParticipant: (state, action: PayloadAction<string[]>) => {
      state.participantData = action.payload;
    },
    FilterLocation: (state, action: PayloadAction<string>) => {
      state.locationData = action.payload;
    },
    FilterDate: (state, action: PayloadAction<string>) => {
      state.dateData = action.payload;
    },
    FilterName: (state, action: PayloadAction<string>) => {
      state.nameData = action.payload;
    }
    
  },
  extraReducers: {},
});

export const { FilterCategory, FilterParticipant, FilterLocation, FilterDate, resetFilters, FilterName } = filterHubReducer.actions;

export default filterHubReducer.reducer;
