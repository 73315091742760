import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosConfig } from "../axios.config";

export const fetchGetAllParticipants = createAsyncThunk(
  "getAllParticipants/fetchGetAllParticipants",
  async (_, thunkAPI) => {
    try {
      const response = await axiosConfig.get<string>(`/timeline/allParticipants`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "get all participants failed"
      );
    }
  }
);
