import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  FieldDescription,
  SmallRadio,
  TextFieldLine,
  UploadImages,
} from "../../../../components";
import styles from "./EditContainer.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { getMemoryIdToken, getTimelineIdToken } from "../../../../hooks/localstorage";
import { Carousel } from "../../../../components/Carousel";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { fetchUpdateFilesMemories } from "../../../../store/api/fetchUpdateFilesMemories";
import { resetUpdateMemoryData } from "../../../../store/feature/memory/updateMemories";
import { resetUpdateMemoryFiles } from "../../../../store/feature/memory/updateFilesMemories";
import { fetchSingleDocument } from "../../../../store/api/fetchSingleDocument";
import { fetchUpdateDocument } from "../../../../store/api/fetchUpdateDocument";
import { fetchDocumentUpdateFiles } from "../../../../store/api/fetchDocumentUpdateFiles";
import { resetUpdateDocument } from "../../../../store/feature/document/updateDocument";
import { resetUpdateDocumentFiles } from "../../../../store/feature/document/updateFilesDocument";
import { fetchDeleteDocument } from "../../../../store/api/fetchDeleteDocument";
import { resetDeleteDocumentData } from "../../../../store/feature/document/deleteDocument";

interface IEditContainer {
  setOpenPopup: Dispatch<SetStateAction<boolean>>;
  setGetPhoto: (value: {}) => void;
}

export const EditContainer: FC<IEditContainer> = ({
  setOpenPopup,
  setGetPhoto,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const id = getMemoryIdToken();
  const timeline = getTimelineIdToken();

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [files, setFiles] = useState<any>(null);
  const [privacy, setPrivacy] = useState<string>("");
  const [ids, setIds] = useState<string>("");
  const [documentFiles, setDocumentFiles] = useState<any>([]);
  const [visualCarousel, setVisualCarousel] = useState<any>([]);
  const [additionalPicture, setAdditionalPicture] = useState<any>([]);
  const [isRequestSent, setIsRequestSent] = useState(false);

  const { data, isLoading } = useAppSelector((state) => state.singleDocumentReducer);
  const { update, Loading, errors } = useAppSelector((state) => state.updateDocumentReducer);
  const { result, isLoad, er } = useAppSelector(
    (state) => state.deleteDocumentReducer
  );
  const handlerDelete = () => {
    dispatch(fetchDeleteDocument(id));
  };
  const { responseData, Load, err } = useAppSelector((state) => state.updateFilesDocumentReducer);

  useEffect(() => {
    if (files != null) {
      setVisualCarousel([...documentFiles, ...files]);
    }
  }, [files]);

  useEffect(() => {
    if (update.id !== null && files === null) {
      dispatch(resetUpdateDocument());
      navigate("/Document");
    } else if (update.id !== null && files !== null && !isRequestSent) {
      const formData = new FormData();
      // formData.append("files", files);
      files.forEach((file: string | Blob) => {
        formData.append("files", file);
      });
      dispatch(fetchDocumentUpdateFiles({ formData: formData, ids: update.id }));
      setIsRequestSent(true);
    } else if (responseData === "OK" && isRequestSent) {
      dispatch(resetUpdateDocument());
      dispatch(resetUpdateDocumentFiles());
      navigate("/Document");
    }
  }, [update.id, responseData, files, isRequestSent, navigate, dispatch, timeline]);

  useEffect(() => {
    if (result === "OK") {
      navigate("/Document");
      dispatch(resetDeleteDocumentData());
    }
  }, [result, navigate, dispatch]);
  const handlerUpdate = () => {
    dispatch(
      fetchUpdateDocument({
        name,
        description,
        privacy,
        documentFiles: additionalPicture.length > 0 ? additionalPicture : documentFiles,
        id: ids,
      })
    );
  };

  useEffect(() => {
    dispatch(fetchSingleDocument(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (data) {
      const { name, description, privacy, documentFiles, _id } = data;
      setName(name);
      setDescription(description);
      setPrivacy(privacy);
      setVisualCarousel(documentFiles);
      setDocumentFiles(documentFiles);
      setIds(_id)
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <div className={styles.loading}>
        {(isLoading || Loading || Load || isLoad) && (
          <TailSpin
            height="50"
            width="50"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        )}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.texts}>
          <div className={styles.column}>
            <TextFieldLine
              label="Name :"
              type="text"
              value={name}
              setValue={setName}
              sx={{ width: "300px" }}
            />
            <FieldDescription
              label="Description :"
              value={description}
              setValue={setDescription}
              sx={{ width: "300px" }}
            />
          </div>
          <div className={styles.radio}>
            <SmallRadio setPrivacy={setPrivacy} privacy={privacy} />
          </div>
          <div className={styles.carousel}>
            <p>Additional media :</p>
            <Carousel
              setOpenPopup={setOpenPopup}
              setGetPhoto={setGetPhoto}
              picture={visualCarousel}
              setPicture={setVisualCarousel}
              setAdditionalPicture={setAdditionalPicture}
            />
          </div>
        </div>
        <div className={styles.texts}>
          <UploadImages
            image={files}
            setImage={setFiles}
            preview={documentFiles}
          />
        </div>
      </div>
      <span
        style={{
          display: "flex",
          justifyContent: "right",
          color: "red",
          fontSize: "13px",
          height: "20px",
        }}
      >
        {errors || err}
      </span>
      <div style={{display:"flex",justifyContent:"right"}}>

      <Button
        title="Delete"
        action={handlerDelete}
        />
      <Button
        sx={{ marginLeft: "20px"}}
        title="Update"
        action={handlerUpdate}
        />
        </div>
    </div>
  );
};
